import http from './httpService';

// Get dashboard homepage data (Ongoing,Upcoming,Completed & Suggested Course)
export function getUserInfo(id) {
    return http.get(`/api/user/${id}/info`);
}

// Get course overview data (Course/Masterclass->Task, Zoom Link, WhatsApp Link, Session Details)

export function getCourseDetails() {
    return http.get(`/api/user/profile/courses`);
}

// Get certificates
export function getUserCertificates() {
    return http.get(`/api/user/certificate/details`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
}

// Download certificates
export function downloadCertificates(id) {
    return http.get(`/api/user/course/${id}/certificate?type=1`, {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
    });
}

export function getTripApplications() {
    return http.get('/api/user/profile/trip/applications');
}

// Resources
export function getResourceFolders() {
    return http.get('/api/user/resource/fs/all');
}

export function getResourceSubfolders(id) {
    return http.get(`/api/user/resource/fs/${id}`);
}

export function getResourceFile(id) {
    return http.get(`/api/user/resource/rf/${id}`);
}

export function getResourceOuterFiles() {
    return http.get('/api/user/resource/outer/files');
}

export function getResourceFiles(fsId) {
    return http.get(`/api/user/resource/fs/${fsId}/files`);
}

export function getSuggestedCourse(data) {
    return http.post('/api/user/profile/course/suggested', data);
}

export function resourceSearchAPI(query) {
    return http.get(`/api/user/resource/search?query=${query}`);
}

export function getResourceDetails(resId) {
    return http.get(`/api/user/resource/${resId}/details`);
}

export function getResourcesCount(id) {
    return http.get(`/api/user/resource/folder/${id}/all`);
}

/**
 Don't remove this code!
 * @returns The `getResourcesPDF` function is returning a Promise that resolves to the URL of a PDF
 * resource.
 */
export function getResourcesPDF(id) {
    return http.get(`/api/resource/pdf/${id}`, {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
        transformResponse: [
            function (data) {
                let blob = new window.Blob([data], { type: 'application/pdf' });
                return window.URL.createObjectURL(blob);
            },
        ],
    });
}

export function getResourcesPDFUrl(id) {
    return http.get(`/api/resource/pdf-url/${id}`);
}



// Course Dashboard APIS
export function getSessionsAssignments(wsId) {
    return http.get(`/api/user/course/${wsId}/module/session`)
}

// Course Dashboard Sessions API (batch wise)
export function getSessionsAssignmentsBatchWise(data) {
    return http.post(`/api/user/course/batch/module/session`, data)
}

// Upload Assignments
export function uploadSessionAssignment(data) {
    return http.post(`/api/user/course/module/session/assignment/response`, data)
}

// Delete / Update Assignments
export function deleteOrUpdateAssignments(data) {
    return http.post(`/api/user/course/module/session/assignment/update`, data)
}

// Get Leaderboard Scores
export function getLeaderboardScores(batchId) {
    return http.get(`/api/user/course/batch/${batchId}/leaderboard/score`);
}
// Assignment Delete 
export function deleteAssignment(arId) {
    return http.delete(`/api/user/course/session/assignment/${arId}/delete`)
}

// Get user assignments 
export function getLeaderboardUserAssignments(batchId) {
    return http.get(`/api/user/course/batch/${batchId}/assignment/points`)
}


// Get Referral Users List
export function getReferralUsers(refCode) {
    return http.get(`/api/user/referral/${refCode}/courses`)
}

// Get Referral Courses List
export function getReferralCourses() {
    return http.get(`/api/referral/landing-page/courses`)
}

// UPI Id add/update
export function addUpdateUPI(data) {
    return http.post(`/api/user/upi/update`, data);
}


// Add Banner Click Event
export function resourceBannerClick(data) {
    return http.post(`/api/user/resource/banner/click`, data);
}


// UTM Param Clicks
export function utmClickCount(data) {
    return http.post(`/api/utm/param/clicks`, data)
}


// Add Course Reviews
export function submitCourseReviews(data) {
    return http.post(`/api/user/feedback/rating/collect`, data)
}


export function getModuleQuizQuestions(mId) {
    return http.get(`/api/user/course/module/${mId}/quiz`)
}

export function submitQuizOption(data) {
    return http.post(`/api/user/quiz/response/submit`, data);
}

export function quizTestRetake(data) {
    return http.post(`/api/user/course/module/quiz/retake`, data);
}


export function getDashboardNotifications(userId) {
    return http.get(`/api/user/${userId}/notifications`)
}

export function markAsReadNotifications(ids) {
    return http.post(`/api/user/notifications/read`, ids);
}


// Masterclass Feedback Submit
export function submitMasterclassFeedback(data) {
    return http.post(`/api/user/workshop/rating/feedback`, data)
}


// GET MASTERCLASS OVERVIEW DATA 
export function getMasterclassDashboardDetails(wsId) {
    return http.get(`/api/user/course/batch/details?ws=${wsId}`)
}